<template lang="html">
  <div class="HeatMap">
    <newPinMap v-for="(image,index) in images" :pins="getPinsByImageIndex(index)" :image="image" v-on:send-answers="$emit('send-answers')"/>
  </div>
</template>

<script>
import { map } from 'lodash';
import newPinMap from '../PinMap/newPinMap';


export default {
  name: 'HeatMap',

  components: {
    newPinMap,
  },

  props:{
    images:{
      type:Array,
    },
    heatmapPointDefinitions:Array,
    containerClassName:{
      type:String,
      default:'HeatMapActivity'
    },
  },

  data() {
    return {
      pins: [],
    };
  },
  methods:{
    getPinsByImageIndex(index){
      return _.filter(this.pins, function (p) { return p.imageIndex == index });
    }
  },
  created(){
    for(let hpd of this.heatmapPointDefinitions){
      let point = {
        identifier: hpd.identifier,
        title: hpd.title,
        type:hpd.type,
        imageIndex:hpd.imageIndex,
        comment: '',
        x: null,
        y: null,
        positionx:null,
        positiony:null,
        added: false,
        dragging: false,
      }
      this.pins.push(point)
    }
    this.$emit('testing-ok')
  },
}
</script>

<style scoped lang="scss">
.HeatMap {
  width: 100%;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: auto auto;
  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
  }
}
</style>

<template>
  <div class="Pin" :class="{'Pin--Active':dragging}" :style="style">
    <svg class="Pin__Shape" @click="handlePinClick" viewBox="0 0 384 512">
      <path :fill="type" d="M192 0C86.4 0 0 86.4 0 192c0 76.8 25.6 99.2 172.8 310.4a24 24 0 0 0 38.4 0C358.4 291.2 384 268.8 384 192 384 86.4 297.6 0 192 0zm112 200a16 16 0 0 1-16 16h-72v72a16 16 0 0 1-16 16h-16a16 16 0 0 1-16-16v-72H96a16 16 0 0 1-16-16v-16a16 16 0 0 1 16-16h72V96a16 16 0 0 1 16-16h16a16 16 0 0 1 16 16v72h72a16 16 0 0 1 16 16z"></path>
      <path fill="white" d="M304 200a16 16 0 0 1-16 16h-72v72a16 16 0 0 1-16 16h-16a16 16 0 0 1-16-16v-72H96a16 16 0 0 1-16-16v-16a16 16 0 0 1 16-16h72V96a16 16 0 0 1 16-16h16a16 16 0 0 1 16 16v72h72a16 16 0 0 1 16 16z"></path>
    </svg>
    <div class="Pin--Title">
      <h4>{{ title }}</h4>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    'title',
    'value',
    'type',
    'positionx',
    'positiony',
    'dragging',
    'added'
  ],

  data() {
    return {
    };
  },

  computed: {
    style() {
      return {
        // top: this.positiony + '%',
        // left: this.positionx + '%',
        transform: 'translate(' + this.positionx + 'px, ' + this.positiony + 'px)',
      };
    },
  },

  methods: {
    handlePinClick(event) {
      const pin = event.target;
    },
  },
}
</script>

<style scoped lang="scss">
.Pin {
  display: inline-flex;
  // margin-left: -16px;
  // margin-top: -30px;
  touch-action: none;
  position: relative;
  z-index: 1;
  &:hover {
    z-index: 2;
    .Pin--Title{
      display: flex;
    }
  }
}

.Pin__Shape {
  width: 30px;
  height: 30px;
  cursor: grab;
}

.Pin--Active .Pin__Shape {
  cursor: grabbing;
  transition: all 500ms cubic-bezier(0, 1.90, 0.80, 0.52);
}

.Pin--Active .Pin--Title{
  display: none!important;
}

.Pin--Title {
  width: fit-content;
  max-width: 200px;
  background-color: #fff;
  box-shadow: 0px 2px 10px #888;
  z-index: 2;
  position: absolute;
  left: 35px;
  padding: 0.5em;
  border-radius: 5px;
  display: none;
  h4{
    width: max-content;
  }
  &:before{
    content: "";
    position: absolute;
    top: 5px;
    left: -17px;
    border-width: 9px;
    border-style: solid;
    border-color: transparent white transparent transparent;
  }
}
</style>
